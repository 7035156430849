import React from "react";
import './Loader.css'
function Loader() {
  return (
    <div class="loader-overlay">
  <div class="loader">
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>
</div>
  );
}

export default Loader;
