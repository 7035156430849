import  { useEffect } from 'react';
import { useSelector } from 'react-redux';

function Favicon() {
    const Theme = useSelector((state) => state.settings.settings);
    const logoUrl = Theme.settingsTheme?.logo;

    useEffect(() => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = logoUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }, [logoUrl]);

    return null; 
}

export default Favicon;
