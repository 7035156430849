import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { settingsReducer } from "./settingsReducer";

const reducers = combineReducers({
    userDetails: userReducer,
    settings: settingsReducer,
});

export default reducers

