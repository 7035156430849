import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const Theme = useSelector((state) => state.settings.settings);
  const [color, setColor] = useState(
    () => Theme?.settingsTheme?.colorSelection || "#15C19C"
  );

  useEffect(() => {
    document.documentElement.style.setProperty('--bs-default', color);
  }, [color,Theme]);

  return (
    <ColorContext.Provider value={{ color, setColor }}>
      {children}
    </ColorContext.Provider>
  );
};

export const useColor = () => useContext(ColorContext);
