import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function Title() {
    const theme = useSelector((state) => state.settings.settings);
    const title = theme?.settingsGeneral?.institutionName;

    useEffect(() => {
        document.title = title || 'Easy Oratorio'; 
    }, [title]); 

    return null; 
}

export default Title;
