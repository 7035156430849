import React from 'react'

function Error() {
  return (
    <div id="page-container" className="main-content-boxed">
      <main id="main-container">
        <div className="hero bg-body-extra-light">
          <div className="hero-inner">
            <div className="content content-full">
              <div className="py-4 text-center">
                <div className="display-1 fw-bold text-primary">
                  <i className="fa fa-exclamation-triangle opacity-50 me-1"></i> 404
                </div>
                <h1 className="fw-bold mt-5 mb-2">Oops.. Hai appena trovato una pagina di errore..</h1>
                <h2 className="fs-4 fw-medium text-muted mb-5">Ci dispiace, ma la pagina che stai cercando non è stata trovata..</h2>
                <a className="btn btn-lg btn-alt-primary" href="/dashboard">
                  <i className="fa fa-arrow-left opacity-50 me-1"></i> Torna indietro
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Error
